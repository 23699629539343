import React, {useState} from 'react';
import {Routes, Route} from 'react-router-dom';
import {HomeConnected} from 'pages/Home';
import {connect, useDispatch} from 'react-redux';
import {ToDo} from 'pages/ToDo';

import s from './style.scss';

import DynamicRemoteApp from '../dynamicRemoteApp/dynamicRemoteApp';
import {setFormAction} from '../../pages/Home/actions';
import {FormType} from '../../pages/ToDo/components/UserInfo/types';
import {Footer, Header} from '../../components';

const RouterComponent = () => {
    const [isFulfilled, setStatus] = useState<boolean>(false);
    const [rebootMf, setRebootMf] = useState<boolean>(true);
    const dispatch = useDispatch();

    const setForm = (form: FormType) => {
        dispatch(setFormAction(form));
    };

    return (
        <div className={s.container}>
            <Header />
            <div className={s.body}>
                <Routes>
                    <Route path="/" element={<HomeConnected />} />
                    <Route
                        path="/todo"
                        element={
                            <ToDo
                                isFulfilled={isFulfilled}
                                setRebootMf={setRebootMf}
                                rebootMf={rebootMf}
                            >
                                <>
                                    <DynamicRemoteApp
                                        readySkeleton={<div style={{color: 'red'}}>ЖДИ...</div>}
                                        errorSkeleton={<div style={{color: 'red'}}>ОШИБКА...</div>}
                                        rebootMf={rebootMf}
                                        remoteAppInfo={{
                                            module: './ButtonQuiz',
                                            scope: 'Quiz',
                                            url: `${
                                                process.env.NODE_ENV === 'production'
                                                    ? 'https://frontstaging-s1.online'
                                                    : 'http://localhost:9001'
                                            }/remoteEntry.js`,
                                        }}
                                        props={{
                                            setValueForm: setForm,
                                            isFulfilled: isFulfilled,
                                            setStatus: setStatus,
                                        }}
                                    />
                                </>
                            </ToDo>
                        }
                    />
                </Routes>
            </div>
            <Footer />
        </div>
    );
};

export default connect((store: {home: {titleHeader: string; titleFooter: string}}) => ({
    titleHeader: store.home.titleHeader,
    titleFooter: store.home.titleFooter,
}))(RouterComponent);
