import React, {useState} from 'react';
import {Button, Drawer} from 'antd';
import {Link} from 'react-router-dom';

import s from './style.scss';
import UserInfo from './components/UserInfo';

const Footer = ({
    setOpen,
    setRebootMf,
    rebootMf,
}: {
    setOpen: (arg: boolean) => void;
    setRebootMf: (arg: boolean) => void;
    rebootMf: boolean;
}) => {
    return (
        <div className={s.footer}>
            <button className={s.defaultButton} onClick={() => setOpen(false)}>
                Закрыть
            </button>
            {/*{<RenderButtons buttons={buttons} />}*/}
            <button className={s.defaultButton} onClick={() => setRebootMf(!rebootMf)}>
                Перезапросить микрофронт
            </button>
            <button className={s.defaultButton}>Завершить</button>
        </div>
    );
};

export const ToDo = ({
    children,
    isFulfilled,
    setRebootMf,
    rebootMf,
}: {
    children?: React.ReactElement;
    isFulfilled?: boolean;
    rebootMf?: boolean;
    setRebootMf: (arg: boolean) => void;
}) => {
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <div className={s.container}>
            <>
                <Button type="primary" onClick={showDrawer}>
                    Открыть модалку
                </Button>
                <Drawer
                    title="Basic Drawer"
                    onClose={onClose}
                    open={open}
                    width={'90%'}
                    maskClosable={false}
                    footer={
                        <Footer
                            setOpen={setOpen}
                            setRebootMf={setRebootMf}
                            rebootMf={Boolean(rebootMf)}
                        />
                    }
                >
                    {children}
                </Drawer>
            </>
            <UserInfo />
            <div className={s.description}>
                Статус заполнения формы: <u>{String(isFulfilled)}</u>
            </div>
            <Link to="/" className={s.link}>
                Назад на главную
            </Link>
        </div>
    );
};
