import {useEffect, useState} from 'react';

const urlCache = new Set();

type GetElementType = {
    url: string;
    onLoad: () => void;
    onError: () => void;
};

const getElement = ({url, onLoad, onError}: GetElementType) => {
    const element = document.createElement('script');

    element.src = url;
    element.type = 'text/javascript';
    element.async = true;

    element.onload = onLoad;

    element.onerror = onError;

    document.head.appendChild(element);

    return () => {
        document.head.removeChild(element);
    };
};

/**
 * useDynamicScript
 * @param {string} url - url
 * @return {object} state
 */
export const useDynamicScript = (url: string, rebootMf: boolean) => {
    const [ready, setReady] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        if (!url) return () => {};

        setErrorLoading(false);

        if (urlCache.has(url)) {
            setReady(true);
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return () => {};
        }

        setReady(false);

        getElement({
            url,
            onLoad: () => {
                urlCache.add(url);
                setReady(true);
            },
            onError: () => {
                setReady(false);
                setErrorLoading(true);
            },
        });

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
    }, [url, rebootMf]);

    return {
        errorLoading,
        ready,
    };
};
